<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'profile', params: { realm: this.$route.params.realmId } }">Profile</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>

        <v-row justify="center" class="py-5 mt-2">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">My Session</h1>
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-overline mb-0">Expires</p>
                <p class="mb-0 pb-0">
                    <span>{{ authenticatedExpiresText }}</span>
                    <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                    </v-btn> -->
                </p>
                <p class="text-overline mb-0">Next refresh</p>
                <p class="mb-0 pb-0">
                    <span>{{ nextRefreshText }}</span>
                    <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                    </v-btn> -->
                </p>
                <!-- <p class="text-overline mb-0 mt-8">Security</p>
                <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'profile-settings-authn' }">Change my login settings</router-link>
                </p> -->
                <!-- <pre>
                    {{ JSON.stringify(this.session, null, 2) }}
                </pre> -->
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
// import { toText } from '@/sdk/time';

export default {
    data() {
        return {
            sessionExpiresText: null,
            refreshAfter: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        authenticatedExpiresText() {
            if (typeof this.session.authenticatedNotAfter === 'number' && this.session.authenticatedNotAfter > 0) {
                // return toText(this.session.authenticatedNotAfter - Date.now());
                return new Date(this.session.authenticatedNotAfter).toString();
            }
            return undefined;
        },
        nextRefreshText() {
            if (typeof this.session.nextRefresh === 'number' && this.session.nextRefresh > 0) {
                // return toText(this.session.nextRefresh - Date.now());
                return new Date(this.session.nextRefresh).toString();
            }
            return undefined;
        },
    },
    methods: {
        async getSession() {
            try {
                await this.$store.dispatch('loadSession');
                // this.$store.commit('loading', { getSession: true });
                // const response = await this.$client.main().authn.get();
                // console.log(`got session info: ${JSON.stringify(response)}`);
                // this.refreshAfter = response.duration;

                // console.log(`next refresh ms ${response.refreshAfter} text ${this.nextRefreshText}`);
            } catch (err) {
                console.error('failed to load session', err);
            } finally {
                // this.$store.commit('loading', { getSession: false });
            }
        },
    },
    mounted() {
        this.getSession();
    },
};
</script>
